import dayjs from 'dayjs'

export function dateFormat (date, fmt = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(date).format(fmt)
}

/**
 * 砸瓦鲁托
 * @param {number} ms - 时停毫秒数
 * @return {Promise<unknown>}
 */
export function sleep (ms) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}
