<template>
  <div class="home">
    <div class="banner">
      <div class="container description-container">
        <div class="banner-description">国内领先的人工智能量化基金，以统计学、数学建模和IT技术为基础，为投资人实现财富持续增值。</div>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="features">
      <b-container class="features-container">
        <b-row no-gutters>
          <b-col lg="6" md="12">
            <div class="feature-1">
              <h4>基金产品</h4>
              <p>为国内外超高净值客户提供可靠的资产管理服务</p>
              <b-button @click="goTo('Grade')" variant="light">了解产品</b-button>
            </div>
          </b-col>
          <b-col lg="6" md="12">
            <div class="feature-2">
              <h4>人工智能量化</h4>
              <p>一流量化系统，专业海归团队，一线交易所做市商</p>
              <b-button variant="light" @click="goTo('About')">了解宽途</b-button>
            </div>
            <div class="feature-3">
              <h4>汇聚顶尖人才</h4>
              <p>汇聚海内外量化精英，打造国际顶级量化对冲基金</p>
              <b-button variant="light">加入宽途</b-button>
            </div>
          </b-col>
        </b-row>

      </b-container>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {},
  methods: {
    goTo (name) {
      this.$router.push({
        name,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.home {
  background: #fff;
}

.banner {
  position: relative;
  background: url("../assets/banner.jpg") center center / cover no-repeat;
  padding: 150px 0;
  z-index: 99;
  transition: all 0.5s ease-out;

  &:after {
    content: "";
    display: block;
    height: 100%;
    background: url(../assets/banner-cover.png) bottom no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    z-index: -1;
  }

  @media (max-width: 768px) {
    &:after {
      display: none;
    }
  }
}

.description-container {
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.banner-description {
  max-width: 520px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  text-align: left;
  color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 99;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: .9;
  background: linear-gradient(-29deg, #616d86, #1f1c2c);
}

.features {
  background: #fff;
  padding: 80px 0;

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
  }
}

.features-container {
  @media (max-width: 768px) {
    padding: 0;
  }
}

[class^=feature-] {
  position: relative;
  z-index: 1;
  padding: 40px 100px 0 80px;

  h4 {
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 24px;
  }

  p {
    position: relative;
    z-index: 1;
    font-size: 20px;
  }

  .btn {
    margin-top: 40px;
    position: relative;
    z-index: 1;
    padding: 0 24px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    border: 1px solid #444;
    border-radius: 4px;
    box-shadow: ~'0 0.02rem 0.04rem rgb(104 89 36 / 25%)';
    background: #fff;

    &:hover {
      box-shadow: ~'0 4px 6px rgb(104 89 36 / 50%)';
      transition: .4s;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-left: 40px;

    h4 {
      font-size: 24px;
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
    }

    .btn {
      margin-top: 24px;

    }
  }
}

.feature-1 {
  min-width: auto;
  width: 100%;
  height: 656px;

  background: url("../assets/home-feature-1.jpg") center bottom no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    margin-left: 0;
    height: 248px;
  }
}

.feature-2 {
  margin-left: 16px;
  height: 320px;
  width: 100%;
  background: linear-gradient(111.93deg, #242424e6 34.95%, #2727270d 87.38%), url("../assets/home-feature-2.jpg");
  background-position: center right;
  background-size: cover;
  color: #fff;

  &:after {
    background: rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    margin-left: 0;
    height: 248px;
    padding-top: 40px;
    padding-left: 40px;
  }
}

.feature-3 {
  min-width: auto;
  margin-left: 16px;
  margin-top: 16px;
  width: 100%;
  height: 320px;
  background: url("../assets/home-feature-3.jpg");
  background-position: center right;

  background-size: cover;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 0;
    height: 248px;
  }
}

</style>
