<template>
  <div v-if="visible" class="notification" :class="[type]">
    <div class="notify-title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    type: String,
    title: String,
    content: String,
    visible: Boolean,
  },
  mounted () {
    this.autoClose()
  },
  updated () {
    this.autoClose()
  },
  methods: {
    autoClose () {
      if (this.visible) {
        setTimeout(() => {
          this.$emit('update:visible', false)
        }, 2000)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.notification {
  background-color: #fff;
  border: 1px solid #000;
  padding: 10px 12px;
  position: fixed;
  transition: all .3s;
  margin: auto;
  left: 0;
  right: 0;
  width: 344px;

  &.error {
    position: absolute;
    top: 40px;
    color: #e77165;
  }

  .notify-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 4px;
  }

  .content {
    font-size: 12px;
    color: #000;
    line-height: 16px;
  }
}
</style>
