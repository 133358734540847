<template>
  <div class="footer">
    <b-container>
      <b-row>
        <b-col md="4" class="mb-4">
          <h3 class="footer-brand">
            <router-link to="/">
              <img class="footer-logo" src="favicon.png" alt="logo">
              <img class="footer-title" src="@/assets/title.png" alt="title">
            </router-link>

          </h3>
          <p>
            国内领先的人工智能量化基金，为客户提供优质的投资产品选择，持续创造价值。
          </p>
        </b-col>
        <b-col md="3" class="mb-4" v-if="false">
          <h3>友情链接</h3>
          <div class="mb-5">
            <ul class="list-inline">
              <li class="mb-3"><a class="footer-link" href="https://www.followb.cn">币秀短视频</a></li>
            </ul>
          </div>
        </b-col>
        <b-col md="4" class="mb-4 mb-lg-0 mb-md-0">
          <h3>量化资源</h3>
          <div class="mb-5">
            <ul class="list-inline">
              <li class="mb-3"><router-link class="footer-link" to="/grade" title="点击查看产品展示">产品展示</router-link></li>
            </ul>
          </div>
        </b-col>
        <b-col md="4" class="mb-lg-0 mb-md-0 col-md-3">
          <h3 class="card-title font-weight-bold">联系我们</h3>
          <div class="mb-5">
            <ul class="list-inline">
              <li class="mb-3"><a title="点击发送邮件" class="footer-link" href="mail://quantofund@163.com"><b-icon icon="mailbox2"></b-icon>&nbsp;quantofund@163.com</a></li>
              <li class="mb-3" v-if="false"><a title="点击拨打电话" class="footer-link" href="tel://abc@gmai.com"><b-icon icon="telephone-fill"></b-icon>&nbsp;13692179756</a></li>
              <li><img class="contact-qrcode" src="@/assets/contact-qrcode.png" alt="扫描二维码添加客服微信" title="扫描二维码添加微信"></li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="less" scoped>
.footer {
  background: linear-gradient(-29deg, #616d86, #1f1c2c);
  padding-top: 30px;
  font-size: 12px;
  color: #ffffff;
}

h3 {
  margin-bottom: 1rem;
}

.footer-brand {
}

.footer-logo {
  height: 1em;
  margin-right: 0.5rem;
}

.footer-title {
  height: 1em;
}

.footer-link {
  color: #fff;
}

.contact-qrcode {
  width: 100px;
}
</style>
