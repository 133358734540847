<template>
  <Dialog title="登录" @close="closeDialog">
    <div class="notification-box">
      <Notification type="error" title="错误" :content="validateErrorMessage" :visible.sync="notificationVisible"/>
    </div>
    <div class="form-box">
      <b-form class="users-form">
        <b-form-group label="手机号">
          <b-form-input placeholder="请输入手机号" v-model="userModel.phone"></b-form-input>
        </b-form-group>
        <b-form-group class="sms-code-box" label="验证码">
          <b-form-input placeholder="请输入验证码" v-model="userModel.smsCode"></b-form-input>
          <div class="sms-code-btn">
            <div v-if="countdownTime < 60">{{ countdownTime }}s</div>
            <div class="send-code" v-else @click="getSmsCode">获取验证码</div>
          </div>
        </b-form-group>
        <b-form-group>
          <b-button style="width: 100%;" @click="handleLogin">登录</b-button>
        </b-form-group>
        <div class="pwd-box">
          <div class="pointer" @click="handleForgetPwd">忘记密码</div>
          <div class="pointer" @click="handleRegister">注册</div>
        </div>
      </b-form>
    </div>
  </Dialog>

</template>

<script>
import Dialog from '@/components/Dialog'
import Notification from '@/components/Notification'
import { getSmsCode, postSmsCode } from '@/api/common'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/config/constants'
import { mapMutations } from 'vuex'

export default {
  name: 'Login',
  components: {
    Notification,
    Dialog,
  },
  data () {
    return {
      countdownInterval: null,
      countdownTime: 60,
      notificationVisible: false,
      validateErrorMessage: '',
      userModel: {
        phone: '',
        password: '',
        smsCode: '',
      },
    }
  },
  methods: {
    ...mapMutations({
      setNeedLogin: 'setNeedLogin',
    }),
    startCountdown () {
      this.countdownInterval = setInterval(() => {
        this.countdownTime--
        if (this.countdownTime <= 0) {
          clearInterval(this.countdownInterval)
          this.countdownTime = 60
        }
      }, 1000)
    },
    closeDialog () {
      this.setNeedLogin(false)
    },
    async getSmsCode () {
      if (!/^1[3-9]\d{9}$/.test(this.userModel.phone)) {
        this.validateErrorMessage = '请输入正确的手机号码'
        this.notificationVisible = true
        return
      }
      await getSmsCode(this.userModel.phone)
      this.startCountdown()
    },
    handleLogin () {
      postSmsCode(this.userModel.phone, this.userModel.smsCode).then(res => {
        localStorage.setItem(ACCESS_TOKEN_KEY, res.data.access)
        localStorage.setItem(REFRESH_TOKEN_KEY, res.data.refresh)
        this.closeDialog()
        location.reload()
      })
    },
    handleForgetPwd () {},
    handleRegister () {},
  },
}
</script>

<style lang="less" scoped>

.sms-code-box {
  position: relative;
}

.sms-code-btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
}

.send-code {
  cursor: pointer;
  color: #000000;

}

.users-form {
  .form-group {
    &:not(:first-child) {
      margin-top: 40px;
    }
  }
}

.pwd-box {
  font-size: 14px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.notification-box {
  position: fixed;
  top: 40px;
  width: 100%;
  left: 0;
}
</style>
