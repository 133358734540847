import { request } from '@/utils/request'

export function getSmsCode (phone) {
  return request.get('/sms_code', { params: { phone } })
}

export function postSmsCode (phone, sms_code) {
  return request.post('/sms_code', {
    phone,
    sms_code,
  })
}

export function getUserInfo () {
  return request.get('/user_info')
}
