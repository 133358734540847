<template>
  <div class="dialog-wrapper">
    <div class="dialog__inner">
      <div v-if="title" class="dialog-title">{{ title }}</div>
      <div class="dialog-close" @click="closeDialog">
        <b-icon icon="x" font-scale="2"/>
      </div>
      <div class="dialog-body">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped>
.dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 999;
  background: #0000001a;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.dialog-title {
  font-weight: bold;
  font-size: 36px;
  color: #000;
  line-height: 36px;
  margin-bottom: 24px;
}

.dialog__inner {
  background: #fff;
  margin: 163px 16px 0;
  position: relative;
  border: 2px solid #000;
  padding: 32px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  @media (min-width: 576px) {
    max-width: 440px;
    padding: 48px 48px 24px 48px;
  }
}

.dialog-close {
  position: absolute;
  top: 32px;
  right: 16px;

  color: #000;
  cursor: pointer;
  @media (min-width: 576px) {
    right: 32px;
  }
}
</style>
