<template>
  <div class="header" :class="{fixed}">
    <b-navbar ref="navbar" class="container bg-transparent main-nav" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand style="cursor:pointer;" @click.stop.prevent="goTo('Home')" title="点击前往首页">
        <img class="logo" src="favicon.png" alt="">
        <img class="title" src="../assets/title.png" alt="">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" class="hide-in-pc" is-nav>
        <b-navbar-nav>
          <template v-for="nav in navs">
            <b-nav-item v-if="!nav.meta.needSuperUser || userInfo.is_superuser" :key="nav.name" @click.stop.prevent="goTo(nav.name)" :active="$route.name===nav.name">{{ nav.meta.title }}</b-nav-item>
          </template>

        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item></b-nav-item>
          <b-nav-item-dropdown right>
            <template #button-content>
              {{ userInfo.phone || '未登录' }}
            </template>
            <b-dropdown-item v-if="userInfo.phone" @click="showLogoutConfirm">退出</b-dropdown-item>
            <b-dropdown-item v-else @click="showLoginDialog">登录</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>

</template>

<script>
import { routes } from '@/router'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Header',
  data () {
    return {
      navs: routes,
      isFixed: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
    }),
    username () {
      return this.userInfo.nickname || this.userInfo.username || this.userInfo.phone
    },
    fixed () {
      return this.$route.meta?.headerFixed || this.isFixed
    },
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      logout: 'logout',
    }),
    goTo (routeName) {
      if (this.$route.name === routeName) {
        return
      }
      this.$router.push({
        name: routeName,
      })
      this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
    },
    handleScroll () {
      if (window.scrollY >= 100 && !this.isFixed) {
        this.isFixed = true
      } else if (window.scrollY < 100) {
        this.isFixed = false
      }
    },
    showLogoutConfirm () {
      const ret = confirm('是否退出登录？')
      if (ret) {
        this.logout()
        window.location.reload()
      }
    },
    showLoginDialog () {
      this.$store.commit('setNeedLogin', true)
    },
  },

}
</script>

<style lang="less" scoped>

.header {
  transition: all 0.5s ease-out;

  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  padding: 20px 0;
  background: transparent;

  &.fixed {
    padding: 0;
    background: linear-gradient(-29deg, #616d86, #1f1c2c);
    box-shadow: ~'0 0 4px rgb(0 0 0 / 14%), 0 4px 8px rgb(0 0 0 / 28%)';
  }

  /deep/ .navbar-collapse {
    @media (max-width: 768px) {
      background: linear-gradient(to left, #616d86, #1f1c2c);
      padding: 1rem;
    }
  }

}

.main-nav {
}

.logo, .title {
  height: 50px;
  margin-right: 10px;
  width: auto;
}

</style>
