<template>
  <div id="app">
    <Header v-if="!fullscreen||showHeader"/>
    <router-view/>
    <Footer v-if="!fullscreen||showFooter"/>
    <Login v-if="needLogin"/>
  </div>
</template>

<script>
import Header from '@/layout/Header'
import Footer from '@/layout/Footer'
import { createWs, WS_URL } from '@/utils/ws'
import Login from '@/layout/Login'
import { mapActions, mapState } from 'vuex'
import { sleep } from '@/utils/time'

export default {
  components: {
    Login,
    Footer,
    Header,
  },
  data () {
    return {
      ws: null,
      wsOpened: false,
      maxRetry: 10,
    }
  },
  computed: {
    ...mapState({
      needLogin: 'needLogin',
      userInfo: 'userInfo',
    }),
    fullscreen () {
      return this.$route.meta?.fullscreen
    },
    showHeader () {
      return this.$route.meta?.showHeader
    },
    showFooter () {
      return this.$route.meta?.showFooter
    },
  },
  created () {
    this.fetchUserInfo()
  },
  mounted () {
    this.connectWs()
  },
  methods: {
    ...mapActions({
      fetchUserInfo: 'fetchUserInfo',
    }),
    connectWs () {
      this.ws = createWs({
        url: WS_URL,
        onopen: (ev) => {
          this.wsOpened = true
          this.maxRetry = 10
          this.$emit('ws-open', ev)
        },
        onmessage: async (data) => {
          this.$emit('ws-message', data)
        },
        onclose: async (ev) => {
          this.$emit('ws-close', ev)
          if (this.maxRetry > 0) {
            await sleep(1000)
            this.connectWs()
            this.maxRetry--
          }
        },
        onerror: (ev) => {
          this.$emit('ws-error', ev)
        },
      })
    },
  },
}
</script>
<style lang="less">
#app {
  min-height: 100%;
}
</style>
