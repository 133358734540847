/**
 * 支持发送json
 * @param {object} [data={}]
 */
WebSocket.prototype.sendJson = function (data = {}) {
  this.send(JSON.stringify(data))
}

/**
 * 订阅ws
 * @param {string[]} [params=[]]
 */
WebSocket.prototype.subscribe = function (params = []) {
  this.sendJson({
    action: 'subscribe',
    params,
  })
}
/**
 * 取消订阅ws
 * @param {string[]} [params=[]]
 */
WebSocket.prototype.unsubscribe = function (params = []) {
  this.sendJson({
    action: 'unsubscribe',
    params,
  })
}

/**
 * 登录接口
 * @param {string} phone
 * @param {string} smsCode
 */
WebSocket.prototype.login = function (phone, smsCode) {
  this.sendJson({
    action: 'login',
    params: [phone, smsCode],
  })
}

/**
 * @callback onopenHandler
 * @param {Event} evt
 * @param {WebSocket} ws
 */

/**
 * @callback onmessageHandler
 * @param {string[]|string} data
 * @param {WebSocket} ws
 */

/**
 * @callback oncloseHandler
 * @param {CloseEvent} evt
 * @param {WebSocket} ws
 */

/**
 * @callback onerrorHandler
 * @param {Event} evt
 * @param {WebSocket} ws
 */

/**
 * @typedef {Object} createWsConfig
 * @property  {string} [url] - WebSocket地址
 * @property  {onopenHandler} [onopen]
 * @property  {onmessageHandler} [onmessage]
 * @property  {oncloseHandler} [onclose]
 * @property  {onerrorHandler} [onerror]
 */

/**
 * 生成WebSocket实例
 * @param  {string} [url] - WebSocket地址
 * @param  {onopenHandler} [onopen]
 * @param  {onmessageHandler} [onmessage]
 * @param  {oncloseHandler} [onclose]
 * @param  {onerrorHandler} [onerror]
 * @param {number} [heartbeat=10]
 * @return {WebSocket}
 */

export function createWs ({
  url,
  onopen = (ev, ws) => {},
  onmessage = (data, ws) => {},
  onclose = (ev, ws) => {},
  onerror = (ev, ws) => {},
  heartbeat = 10,
}) {
  let heartbeatTimeout = null

  const ws = new WebSocket(url)
  const sendHeartbeat = () => {
    heartbeatTimeout = setTimeout(() => {
      ws.send('ping')
    }, heartbeat * 1000)
  }
  ws.onopen = (ev) => {
    if (heartbeat) {
      sendHeartbeat()
    }
    onopen(ev, ws)
  }
  ws.onmessage = (ev) => {
    clearTimeout(heartbeatTimeout)
    if (heartbeat) {
      sendHeartbeat()
    }
    try {
      onmessage(JSON.parse(ev.data), ws)
    } catch (e) {
      onmessage(ev.data, ws)
    }
  }
  ws.onclose = (ev) => onclose(ev, ws)
  ws.onerror = (ev) => onerror(ev, ws)

  return ws
}

export const WS_URL = process.env.VUE_APP_WS_URL
