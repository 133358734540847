import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import '@/assets/styles/base.less'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/styles/override-bootstrap.less'
import '@/assets/styles/common.less'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

new Vue({
  ...App,
  router,
  store,
}).$mount('#app')
