import Vue from 'vue'
import Vuex from 'vuex'
import { clearToken, getAccessToken } from '@/utils/request'
import { getUserInfo } from '@/api/common'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access: getAccessToken(),
    needLogin: false,
    userInfo: {},
  },
  mutations: {
    setNeedLogin (state, needLogin) {
      state.needLogin = needLogin
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
  },
  actions: {
    logout (context) {
      const { commit } = context
      commit('setUserInfo', {})
      clearToken()
    },
    async fetchUserInfo (context) {
      const {
        commit,
        state,
      } = context
      if (!state.access) {
        return
      }
      await getUserInfo().then(res => {
        if (!res.code) {
          commit('setUserInfo', res.data)
        }
      })
    },
  },
  modules: {},
})
