import axios from 'axios'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/config/constants'
import { toSnakeData } from '@/utils/object'

export const baseURL = process.env.VUE_APP_BASE_URL

export const request = axios.create({
  baseURL,
})

request.interceptors.request.use((config) => {
  if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN_KEY)
  }
  if (config.data) {
    config.rawData = config.data
    config.data = toSnakeData(config.data)
  }

  if (config.params) {
    config.rawParams = config.params
    config.params = toSnakeData(config.params)
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

request.interceptors.response.use((response) => {
  if (response.config.original) {
    return response
  }
  return response.data
}, (error) => {
  console.log(error)
  const response = error.response
  if (response?.status === 401) {
    return refresh().then(() => {
      error.config.data = error.config.rawData
      error.config.params = error.config.rawParams
      // return request(error.config).catch(error => Promise.reject(error))
    }).catch(() => unauthenticated(error))
  } else {
    if (response?.data?.code && response?.data?.message) {
    }
  }

  return Promise.reject(error)
})

function refresh () {
  const rst = localStorage.getItem(REFRESH_TOKEN_KEY)
  if (!rst) {
    return Promise.reject(new Error('无效的认证信息'))
  }
  return axios.post('/api/token/refresh', {
    refresh: rst,
  }).then(res => {
    localStorage.setItem(ACCESS_TOKEN_KEY, res.data.data.access)
    location.reload()
    return res
  })
}

async function unauthenticated (error) {
  clearToken()
  return Promise.reject(error)
}

export function clearToken () {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

export function getAccessToken () {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}
