import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
    },
  },
  {
    path: '/grade',
    name: 'Grade',
    component: () => import('@/views/Grade'),
    meta: {
      title: '产品展示',
      headerFixed: true,
      fullscreen: true,
      showHeader: true,
      needSuperUser: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About'),
    meta: {
      title: '了解宽途',
    },
  },
  {
    path: '/assets',
    name: 'Assets',
    component: () => import('@/views/Assets'),
    meta: {
      title: '账户资产',
      headerFixed: true,
      fullscreen: true,
      showHeader: true,
    },
  },
]

export const router = new VueRouter({
  routes: [
    ...routes,
    {
      path: '/fund-product-detail',
      name: 'FundProductDetail',
      component: () => import('@/views/FundProductDetail'),
      meta: {
        title: '基金详情',
        headerFixed: true,
      },
    },
    {
      path: '/asset-account-detail',
      name: 'AssetAccountDetail',
      component: () => import('@/views/AssetAccountDetail'),
      meta: {
        title: '账户详情',
        headerFixed: true,
      },
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    }
  },
})
